import { throttle } from "throttle-debounce";
import MainCarousel from "./main-carousel";
import CarouselSection from "./carousel-section";
//import AppHelpers from "VideoPillar/helpers";
import PlayButtonIcon from "VideoPillar/svgs/play-button";
import PromoFeed from "./promo-feed";
const orgServer = app.config.envDom.replace(".medscape.com", ".medscape.org");
const srcMarker = "vid_pil";
window.googletag = window.googletag || { cmd: [] };
const { googletag } = window;
let renderedSlots = [];

if (googletag.pubads) {

  googletag.pubads().addEventListener('slotRenderEnded', event => {
    const { slot } = event;
    //console.log("[video-pillar][initAdPlacements][slotRenderEnded] slot:", slot);
    renderedSlots.push(slot);
  });
}
else {
  googletag.cmd.push(() => {
    googletag.pubads().addEventListener('slotRenderEnded', event => {
      const { slot } = event;
      //console.log("[video-pillar][initAdPlacements][slotRenderEnded] slot:", slot);
      renderedSlots.push(slot);
    });
  })
}

class App {
  constructor(config) {
    this.config = config;
    this.standardSections = [];
  }
  init() {
    this.initMainCarousel();
    this.insertPlayButtonIcon();
    this.initFeaturedCarousel();
    this.initStandardCarousels();
    $("#mainContainer").css("opacity", "1");
    this.initAdPlacements();
    this.initPromoFeed();
  }
  getSrcMarkerUrl(_url) {
    let url = _url;
    url += _url.indexOf("?") > -1 ? "&" : "?";
    url += "src=" + srcMarker;
    return url;
  }
  insertPlayButtonIcon() {
    $("main")
      .find(".play-button-wrapper .play-button")
      .each((index, buttonEl) => {
        $(buttonEl).append(PlayButtonIcon);
      });
  }
  initMainCarousel() {
    if ($("#video-pillar-carousel").length == 0) return;
    this.config.mainCarousel.slides.forEach((slide) => {
      slide.url = this.getSrcMarkerUrl(slide.url);
    });
    this.mainCarousel = new MainCarousel(this.config.mainCarousel);
    this.mainCarousel.init();
  }
  initFeaturedCarousel() {
    let $featuredCarousel = $(".featured-carousel");
    if ($featuredCarousel.length == 0) return;
    $featuredCarousel.find("a").each((index, el) => {
      let $el = $(el);
      let href = $el.attr("href");
      $el.attr("href", this.getSrcMarkerUrl(href));
    });

    let featuredSection = new CarouselSection(
      $featuredCarousel,
      this.config.featuredSection
    );
    featuredSection.init();
  }
  initStandardCarousels() {
    let standardCarousels = [
      ...document.querySelectorAll(".standard-carousel"),
    ];
    //console.log("[video pillar] standardCarousels:", standardCarousels);
    if (standardCarousels.length > 0) {
      let { standardSections } = this.config;

      standardCarousels.forEach((standardCarousel, index) => {
        if (index < standardSections.sections.length) {
          let standardConfig = {
            data: standardSections.sections[index],
            carouselConfig: standardSections.carouselConfig,
          };
          const { urlParams, isMedscapeOrg } = standardConfig.data;
          let linkEls = standardCarousel.querySelectorAll("a");
          linkEls.forEach((linkEl) => {
            let href = linkEl.getAttribute("href");
            if (isMedscapeOrg) {
              href = `${orgServer}${href}`;
            } else {
              const { protocol, host } = document.location;
              href = `${protocol}//${host}${href}`;
            }

            if (urlParams != null && urlParams.length > 0) {
              let url = new URL(href);
              urlParams.forEach((param) => {
                const { name, value } = param;
                url.searchParams.set(name, value);
                href = url.href;
              });
            }
            linkEl.setAttribute("href", href);
          });

          let standardSection = new CarouselSection(
            $(standardCarousel),
            standardConfig
          );
          standardSection.init();
        }
      });
    }
    this.standardSections = standardCarousels;
  }
  initAdPlacements() {
    

    const defineAd = ({ target, adPos }) => {
      //googletag.pubads().updateCorrelator();
      //console.log("[video-pillar][defineAd] target:", target )
      webmd.ads2.defineAd({ id: target.id, pos: adPos });


      const { slot } = webmd.ads2.ads[target.id];
      //console.log("[video-pillar][defineAd] webmd.ads2.displayCalled :", webmd.ads2.displayCalled )
      //console.log("[video-pillar][defineAd] renderedSlots.length:", renderedSlots.length )
      if (webmd.ads2.displayCalled && renderedSlots.length > 0) {
        const renderedSlot = renderedSlots.find(_slot => {
          return slot === _slot
        })
        if (renderedSlot == null) {

          // WARNING: webmd.ads2.refresh DOES NOT SUPPORT DUPLICATE 'pos'
          //webmd.ads2.refresh({ id: target.id,  pos: adPos, slot });
          
          googletag.cmd.push(() => {
            //console.log("[video-pillar][defineAd] googletag.display - slot:", slot )
            googletag.display(slot);
          })
          googletag.cmd.push(() => {
            //console.log("[video-pillar][defineAd] googletag.refresh - slot:", slot )
            googletag.pubads().refresh([slot]);
          });
          
        }
      }

    }
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          //observer.disconnect();

          const { target } = entry;
          observer.unobserve(target);
          const adPos = window.parseInt(target.dataset.adPos, 10);
          defineAd({ target, adPos, lazyload: true });
        }
      });
    };
    const intersectionObserver = new IntersectionObserver(callback);

    const sects = document.querySelectorAll("section.standard-carousel");
    const adPos = DFPTargetKeys.reqHeaders.device == "MOBILE" ? 1122 : 141;

    let suffix = 0;
    sects.forEach((sect, index) => {
      if ((index % 2) === 1) {
        const adDiv = document.createElement("div");
        const adPosId = `ad-pos-${adPos}_${suffix}`;
        adDiv.dataset.adPos = adPos;
        adDiv.id = adPosId;
        adDiv.classList.add('AdUnit');
        adDiv.classList.add('pt-10');
        if (typeof sect.nextSibling !== "undefined") {
          sect.parentNode.insertBefore(adDiv, sect.nextSibling);
        } else {
          // insert after last section
          sect.parentNode.appendChild(adDiv);
        }
        intersectionObserver.observe(adDiv);
        suffix++;

      }
    });
  }
  initPromoFeed() {
    // https://jira.internetbrands.com/browse/PPE-443787
    const initAdsContainers = () => {
      const adsPositions = [300, 301, 302, 303];
      const sizes = [2, 12];
      adsPositions.forEach((adPos) => {
        const adDiv = document.createElement("div");
        const adPosId = `ad-pos-${adPos}`;
        adDiv.id = adPosId;
        adDiv.className = `AdUnit`;

        document.body.appendChild(adDiv);
        const defineAdData = {
          id: adPosId,
          pos: adPos,
          sizes: sizes,
          collapseBefore: true,
          collapseAfter: true,
        };
        webmd.ads2.defineAd(defineAdData);
      });

    };
    let promoData = {
      header: "FEATURED EXPERTS (Information From Industry)",
      slides: [],
    };
    let receivedSlides = 0;
    let blankAds = 0;
    const receiveMessage = (event) => {
      const { origin, data } = event;
      /*
      console.log(
        "[Post Message] event:",
        event,
        "\nOrigin:",
        origin,
        "\nData:",
        data
      );
      */
      const isDevEnv = window.location.hostname.includes("localhost");
      const adsPositions = [300, 301, 302, 303];
      const isValidOrigin =
        isDevEnv ||
        origin.includes("tpc.googlesyndication.com") ||
        origin.includes("medscape.com");
      if (isValidOrigin === true) {
        if (typeof data === "object" &&
          data.type != null &&
          data.type.includes("VIDEO-PILLAR:DFP:PROMO-TL-FEED")) {
          if (data.type.includes(":HEADER")) {
            promoData.header = data.value;
          } else if (data.type.includes(":SLIDES")) {
            receivedSlides++;
            promoData.slides = [...promoData.slides, ...data.configs];
          }
        }
        if (typeof data === 'string') {
          let parsedData = JSON.parse(data);
          if (typeof parsedData === 'object' &&
            parsedData.callBack == 'getIframeParentElement' &&
            adsPositions.includes(parseInt(parsedData.adpos, 10))) {
            blankAds++;
            receivedSlides++;
            promoData.slides = [...promoData.slides, { index: parseInt(parsedData.adpos, 10), empty: true }];
          }
        }
        //console.log('receivedSlides', receivedSlides);
        //console.log('promoData', promoData);
        if (
          receivedSlides > 3 &&
          promoData.slides.length > 0 &&
          promoData.header !== null
        ) {
          window.removeEventListener("message", receiveMessage, false);
          const { carouselConfig } = this.config.standardSections;
          let container = document.createElement("section");
          container.classList.add("standard-carousel");
          container.classList.add("promo-feed-carousel");
          container.classList.add("resp-container");
          const targetNode = this.standardSections[2];
          const { parentNode } = targetNode;
          parentNode.insertBefore(container, targetNode);
          let promoFeed = new PromoFeed(container, promoData, carouselConfig);
          if (blankAds < 4) {
            promoFeed.render();
          }
        }
      }
    };
    window.addEventListener("message", receiveMessage);
    initAdsContainers();
  }
}
App.init = () => {
  let app = new App();
  app.init();
};

export default App;
