import $ from 'jquery';
import 'VideoPillar/libs/slick';
import Slide from './slide';
import AppHelpers from 'VideoPillar/helpers';
import DefaultSlickConfig from './default-slick-config';
export default class MainCarousel{
	constructor(config=null){
		this.config = $.extend({},config,true);
		this.slides = [];
	}
	getConfig(){
		return $.extend(true,{},DefaultSlickConfig,this.config.carouselConfig);
	}
	init(){
		this.config.slides.forEach( (slideData)=>{
			//let config = $.extend(true,{},this.config.contentConfig,slideData);
			let config = $.extend(true,{},slideData);
			let slide = new Slide(config);
			slide.render();
			this.slides.push(slide);
		});
		//console.log("Slides:\n",this.slides.join(""));
		let $VideoPillarCarousel = $('#video-pillar-carousel');
		$VideoPillarCarousel.append(this.slides.join(""));
		$VideoPillarCarousel.slick(this.getConfig());
	}

}