import $ from 'jquery';
import 'VideoPillar/libs/slick';
import DefaultSlickConfig from './default-slick-config';
function createSlides(slides = [])
{
    let configs = slides.sort((slideA, slideB) => {
        return slideA.index - slideB.index
    })
    console.log("[PromoFeed] createSlides - configs:",configs);
    configs = configs.filter( slide => {
        return slide.empty != true
    });
    let slidesHtml = configs.map( slide => {
        const { url, image, date, title, label } = slide;
        const dateHtml = date == null || date.trim() === "" ? "" : `<div class="date">${date}</div>`;
        const labelHtml = label == null || label.trim() === "" ? "" : `<div class="label">${label}</div>`;
        let html = `
        <div class="promo-item standard-item">
            <a href="${url}">
                <div class="poster-container" style="background-image: url('${image}'); ">
                    <span class="play-button-wrapper">
                        <span class="play-button"><svg viewBox="0 0 41.999 41.999">
                        <path d="M36.068,20.176l-29-20C6.761-0.035,6.363-0.057,6.035,0.114C5.706,0.287,5.5,0.627,5.5,0.999v40  c0,0.372,0.206,0.713,0.535,0.886c0.146,0.076,0.306,0.114,0.465,0.114c0.199,0,0.397-0.06,0.568-0.177l29-20  c0.271-0.187,0.432-0.494,0.432-0.823S36.338,20.363,36.068,20.176z" fill="#FFFFFF"></path>
                      </svg></span>
                    </span>
                </div>
                <div class="meta-data-container">
                    ${dateHtml}
                    ${labelHtml}
                    <div class="title">${title}</div>
                </div>
            </a>
        </div>
        `
        return html;
    })
    //console.log("[PromoFeed] createSlides - html:",slidesHtml);
    return slidesHtml.join("\n");
}


export default class PromoFeed{
	constructor(container,config=null,slickConfig){
		this.slickConfig = Object.assign({}, DefaultSlickConfig.StandardConfig,slickConfig);
		this.config = config;
        this.container = container;

	}

	render(){
        const { header, slides } = this.config;
        const headerEl = document.createElement('h2');
        headerEl.innerHTML = header;
        
        let slickContainer = document.createElement('div');
        slickContainer.classList.add('slick-container');
        slickContainer.classList.add('clearfix');
        let slidesHtml = createSlides(slides);
        slickContainer.innerHTML = slidesHtml;

        this.container.appendChild(headerEl);
        this.container.appendChild(slickContainer);
        $(slickContainer).slick(this.slickConfig);
                
	}

}
