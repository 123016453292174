import Handlebars from 'handlebars';
import AppHelpers from 'VideoPillar/helpers';
import DefaultSlickConfig from './default-slick-config';

class CarouselSection{
	constructor($el,config){
		this.config = config;
		this.$el = $el;
	}
	init(){
		//this.$el.find('h2').html(this.config.data.header);
		let template = Handlebars.compile(this.$el.html())
		let html = template(this.config.data);
		this.$el.html(html);
		let $carouselContainer = this.$el.find('.slick-container');
		if($carouselContainer.length==0) return;

		const isFeaturedSection = this.$el.hasClass('featured-carousel');
		const defaultSlickConfig = isFeaturedSection ? DefaultSlickConfig.FeaturedConfig : DefaultSlickConfig.StandardConfig;
		//console.log("DefaultSlickConfig:",isFeaturedSection,"\n",DefaultSlickConfig);
	    let config = $.extend(true,{},defaultSlickConfig,this.config.carouselConfig);
	    //console.log("config:",config);
	    $carouselContainer.slick(config);
	    $carouselContainer.on('beforeChange',function(event, slick, currentSlide, nextSlide){
  			let lazyInstance = slick.$slides.find('[data-src]').data("plugin_lazy");
  			if(lazyInstance){
  				lazyInstance.loadAll();
  			}
  			$carouselContainer.addClass('sliding');
	    }).on('afterChange', function(event, slick, currentSlide, nextSlide){ 			
  			$carouselContainer.removeClass('sliding');

		});
		$carouselContainer.find('[data-src]').lazy({
			visibleOnly: true
		});
	}
	
}

export default CarouselSection;