import AppHelpers from 'VideoPillar/helpers';
import CarouselArrow from 'VideoPillar/svgs/carousel-arrow';
const NextArrow =  `<div class="arrow-mask next"><button type="button" class="slick-next">${CarouselArrow}</button></div>`;
const PrevArrow = `<div class="arrow-mask prev"><button type="button" class="slick-prev">${CarouselArrow}</button></div>`;
/*
export default {  
   infinite:true,
   respondTo: 'window',
   dots: true,
   slidesToShow:4,
   slidesToScroll:4,
   arrows:(!AppHelpers.isMobile() && !AppHelpers.isIpad()),
   nextArrow: `<div class="arrow-mask next"><button type="button" class="slick-next">${CarouselArrow}</button></div>`,
   prevArrow: `<div class="arrow-mask prev"><button type="button" class="slick-prev">${CarouselArrow}</button></div>`
}
*/
export default {
	"FeaturedConfig": {
      "infinite": true,
      "dots": true,
      "slidesToShow": 3,
      "slidesToScroll": 3,
      "centerMode": false,
      "arrows": true,
      "nextArrow": NextArrow,
      "prevArrow": PrevArrow,
      "respondTo": 'window',
      "responsive": [
        /* 320 design */
        {
          "breakpoint": 481,
          "settings": {
            "infinite": true,
            "initialSlide": 0,
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "arrows": false,
            "centerMode": false,
            "centerPadding": "0px",
            "dots": false
          }
        },
        /* 768 design */
        {
          "breakpoint": 769,
          "settings": {
            "infinite": true,
            "initialSlide": 0,
            "slidesToShow": 2,
            "slidesToScroll": 2,
            "arrows": false,
            "centerMode": false,
            "centerPadding": "0px"
          }
        },
        /* 1024 design */
        {
          "breakpoint": 1025,
          "settings": {
            "infinite": true,
            "initialSlide": 0,
            "slidesToShow": 2,
            "slidesToScroll": 2,
            "arrows": true,
            "centerMode": false,
            "centerPadding": "0px"
          }
        }
      ]
    },
   	"StandardConfig":{
      "infinite": true,
      "lazyLoad": 'ondemand',
      "dots": true,
      "slidesToShow": 4,
      "slidesToScroll": 4,
      "arrows": true,
      "nextArrow": NextArrow,
      "prevArrow": PrevArrow,
      "centerMode": false,
      "centerPadding": 0,
      "respondTo": 'window',
      "responsive": [
        /* 320 design */
        {
          "breakpoint": 481,
          "settings": {
            "infinite": true,
            "initialSlide": 0,
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "arrows": false,
            "centerMode": false,
            "centerPadding": "0px",
            "dots": false
          }
        },
        /* 768 design */
        {
          "breakpoint": 769,
          "settings": {
            "infinite": true,
            "initialSlide": 0,
            "slidesToShow": 2,
            "slidesToScroll": 2,
            "arrows": false,
            "centerMode": false,
            "centerPadding": "0px"
          }
        },
        /* 1024 design */
        {
          "breakpoint": 1025,
          "settings": {
            "infinite": true,
            "initialSlide": 0,
            "slidesToShow": 3,
            "slidesToScroll": 3,
            "arrows": true,
            "centerMode": false,
            "centerPadding": "0px"
          }
        }
      ]
    }
}