import $ from 'jquery';
import classNames from 'classnames';
import Handlebars from 'handlebars';
import AppHelpers from 'VideoPillar/helpers';
import SlideTemplate from './templates/slide.html';

class Slide {
  constructor(config) {
    this.config = config;
    this.buildData();
    this.template = Handlebars.compile(SlideTemplate);
    this.HTML = null;
  }
  buildData() {
    let config = this.config;
    config.appImagePath = AppHelpers.getAppImagePath();
    config.instanceImagePath = AppHelpers.getInstanceImagePath();
    //config.isHeaderImage = (config.header.endsWith('.svg') || config.header.endsWith('.jpg') || config.header.endsWith('.gif'));
    config.brandLogo = config.header ? AppHelpers.compileHtml(config.header) : '';
    config.bannerImage.src = AppHelpers.isMobile() ? config.bannerImage.mobile : config.bannerImage.desktop;
    config.seasonEpisode = "";
    if(config.season){
      config.seasonEpisode = `Season ${config.season} &nbsp;:&nbsp; Episode ${config.episode}`;
    }
    else if(config.episode){
      config.seasonEpisode = `Episode ${config.episode}`;
    }

  }
  render() {
    this.HTML = this.template(this.config);
  }
  toString() {
    return this.HTML;
  }
}
export default Slide;