import "core-js";
import 'VideoPillar/scss/main.scss';
import 'VideoPillar/scss/video-pillar.scss';
import 'VideoPillar/scss/mouse-animations.scss';
import $ from 'jquery';
import 'VideoPillar/libs/jquery-lazy';
import 'VideoPillar/libs/slick';
//import 'jquery-lazy/jquery.lazy.plugins';
import 'handlebars';
//import './vendor';

import App from 'VideoPillar/app';

let app = new App(window.config);


$(document).ready(()=>{
	$(document).one('touchstart',(e)=>{
		$('#mainContainer').addClass('disable-mouse-animations');
	})
	app.init();
});

