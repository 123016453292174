import AppHelpers from 'VideoPillar/helpers';
import CarouselArrow from 'VideoPillar/svgs/carousel-arrow';
export default 
{
      "accessibility": true,
      "adaptiveHeight": false,
      "dots": true,
      "infinite": true,
      "speed": 1500,
      "slidesToShow": 1,
      "slidesToScroll": 1,
      "arrows": true,
      "nextArrow": `<button type="button" class="slick-next">${CarouselArrow}</button>`,
      "prevArrow": `<button type="button" class="slick-prev">${CarouselArrow}</button>`,
      "autoplay": true,
      "autoplaySpeed": 5000,
      "fade": false,
      "centerMode": false,
      "responsive": [
        {
          "breakpoint": 769,
          "settings": {
            "arrows": false,
            "fade": false
          }
        }
      ]
    }
