import Handlebars from 'handlebars';
let DFPTargetKeys = window.DFPTargetKeys || {};
DFPTargetKeys.reqHeaders = DFPTargetKeys.reqHeaders || {};
DFPTargetKeys.reqHeaders.device = DFPTargetKeys.reqHeaders.device || "";
let breakpoint = window.breakpoint || -1;
class Helpers{
	constructor(){}

	getAppConfig(){
		return window.app.config;
	}
	getAppConfigData(){
		return {
			imgServer: app.config.imgServer,
			instanceImgPath: app.config.imgServer+app.config.instanceImgPath,
			appImagesPath:  app.config.imgServer+app.config.appImgs,
			appServer: app.config.envDom
		}
	}
	getAppServer(){
		return window.app.config.envDom;
	}
	compileHtml(templateHtml){
		let template = Handlebars.compile(templateHtml);
		let data = this.getAppConfigData();
		return template(data);
	}
	getInstanceConfig(){
		return window.config || null;
	}
	getInstanceImagePath(){
		let imgs = window.app.config.instanceImgPath;
		if(imgs.endsWith('/')){
			imgs = imgs.substring(0,appImgs.length-1);
		}
		return this.getImageServer()+imgs;
	}
	getAppImagePath(){
		let appImgs = window.app.config.appImgs;
		if(appImgs.endsWith('/')){
			appImgs = appImgs.substring(0,appImgs.length-1);
		}
		return this.getImageServer()+appImgs;
	}
	getImageServer(){
		return window.app.config.imgServer;
	}

	isIpad(){
		return /iPad/i.test(window.navigator.userAgent);
	}
	isMobile(){
	    if (breakpoint == 1 || DFPTargetKeys.reqHeaders.device == "MOBILE") {
	      return true
	    } 
	    else if (breakpoint == 2 || DFPTargetKeys.reqHeaders.device == "IPAD") {
	      return true;
	    } 
	    else {
	      return false;
	    }
	}
}
export default new Helpers();